import React from 'react'
import styled from 'styled-components'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Menu from '../../components/Menu'
import Contact from '../../components/Contact'
import ContactForm from '../../components/ContactForm'

const Section = styled.section`
  width: 100%;
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
`

const ContactPage = () => (
  <Layout>
    <SEO title='Contact Us' pathname='/contact/' />
    <Menu />
    <Section>
      <h1>Contact Us</h1>
      <ContactForm />
    </Section>
    <Contact />
  </Layout>
)

export default ContactPage

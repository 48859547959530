import React from 'react'

import styled from 'styled-components'
import Media from '../utils/style-utils'

const FormWrapper = styled.div`
  width: 700px;
  margin: 4rem auto 8rem;

  .hidden {
    display: none;
  }

  label {
    display: inline-block;
    width: 80px;
    margin-right: 15px;
    text-align: left;
  }

  input,
  textarea {
    padding: 10px 15px;
    width: 600px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    outline: none;
  }

  input:focus,
  textarea:focus {
    border-color: #2f338f;
  }

  textarea {
    vertical-align: top;
  }

  button {
    width: 600px;
    margin-left: 95px;
    padding: 15px;
    background-color: #2f338f;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #40439a;
  }

  ${Media.handheld`
    width: 100%;
    padding: 0 20px;

    label {
      display: block;
      margin-bottom: 15px;
    }

    input,
    textarea {
      width: 100%;
      margin-bottom: 2rem;
    }

    button {
      width: 100%;
      margin-left: 0;
    }
  `}
`
export default () => (
  <FormWrapper>
    <form
      name='contact'
      method='POST'
      data-netlify='true'
      netlify-honeypot='bot-field'
      action='/contact/success'
    >
      <p class='hidden'>
        <label>
          <input type='hidden' name='form-name' value='contact' />
        </label>
      </p>
      <p class='hidden'>
        <label>
          Don’t fill this out if you're human: <input name='bot-field' />
        </label>
      </p>

      <div>
        <label htmlFor='name'>Name</label>
        <input
          type='text'
          id='name'
          name='name'
          placeholder='အမည်'
          autoFocus='autoFocus'
          required
        />
      </div>

      <div>
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          id='email'
          name='email'
          placeholder='email'
          required
        />
      </div>

      <div>
        <label htmlFor='content' style={{ marginTop: '10px' }}>
          Content
        </label>
        <textarea
          id='content'
          name='content'
          rows='10'
          cols='33'
          placeholder='အကြောင်းအရာ'
          required
        />
      </div>

      <button type='submit'>Submit</button>
      <p style={{ textAlign: 'left', marginTop: '4rem', fontSize: '0.9rem' }}>
        အထက်ဖော်ပြပါ form မှ ဆက်သွယ်၍မရပါက{' '}
        <a href='mailto:info@myopainting.com'>info@myopainting.com</a> သို့
        email ဖြင့် တိုက်ရိုက်ဆက်သွယ် မေးမြန်းနိုင်ပါသည်။
      </p>
    </form>
  </FormWrapper>
)
